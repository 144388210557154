
import { defineComponent, onMounted, PropType, Ref, ref } from "vue";
import { IAlert } from "@/models/common";
import { messageType } from "@/models/message";
import { useI18n } from "vue-i18n";
import ConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";
import { useConfirm } from "primevue/useconfirm";
export default defineComponent({
  name: "Alert",
  emits: ["update:clear"],
  props: {
    alert: {
      type: Object as PropType<IAlert>,
      required: true,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state: Ref<IAlert> = ref({
      type: props.alert.type ?? messageType.ERROR,
      content: props.alert.content ?? "",
      callback: props.alert.callback ?? null,
      title: props.alert.title
        ? props.alert.title
        : props.alert.type === messageType.INFO
        ? "확인"
        : "오류",
      isConfirm: props.isConfirm,
    });

    const { t } = useI18n({ useScope: "global" });

    const confirm = useConfirm();
    const isConfirmButton = () => {
      confirm.require({
        header: state.value.title?.valueOf().toString(),
        message: state.value.content.toString(),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "확인",
        group: "isConfirm",
        accept: () => {
          if (state.value.callback) state.value.callback();
          clearContent();
        },
        reject: () => {
          clearContent();
        },
      });
    };
    const clearContent = () => {
      emit("update:clear");
    };

    onMounted(() => {
      isConfirmButton();
      // console.log(state.value);
    });

    return { state, isConfirmButton, t };
  },
  components: {
    ConfirmDialog,
  },
});
