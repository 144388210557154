import api from "@/api/api";

import {ApiTextChat} from '.'
const TextChatService = {
  async getLastMessage(roomName:string) {
    const params = {
      roomName
    }
    return await api.get(ApiTextChat.GET_LASTMESSAGE, {params});
  }
}

export default TextChatService