import api from "../api/api";
import { ApiStudyData } from "./";


export const StudyService = {
 
  async getBase64StudyThumbnail(studyKey:String){
    const params = {studyKey};

    return await api.get(ApiStudyData.BASE64_STUDY_THUMBNAIL, {params});
  },
  
}
export default StudyService;