import { OnlineCare, OnlineCareQuick } from "@/models/onlineCare";
import { ICancelRequestParam, RequestKindTypes } from "@/models/request";
import { SecondOpinion } from "@/models/secondOpinion";
import api from "../api/api";
import { ApiRequest } from "./";


export const RequestService = {
  async getRequestList(options:Object){
    return await api.post(ApiRequest.REQUEST_LIST, options);
  },
  async getRequestListItem(key:Number){
    const params={
      key
    };    
    return await api.get(ApiRequest.REQUEST_LIST_ITEM, {params});
  },
  async getRequest(key:Number){
    const params={
      key
    };
    return await api.get(ApiRequest.GET_REQUEST, {params});
  },
  async getRequestResponse(key:Number){
    const params={
      key
    };
    return await api.get(ApiRequest.GET_REQUEST_RESPONSE, {params});
  },
  async getRequsetSummary(requestKind:RequestKindTypes){
    const params={
      requestKind
    };
    return await api.get(ApiRequest.GET_REQUEST_SUMMARY, {params});
  },
  async save(data:OnlineCare|SecondOpinion|OnlineCareQuick){    
    if(data instanceof OnlineCare)return await api.post(ApiRequest.SAVE, data);
    else if(data instanceof OnlineCareQuick)return await api.post(ApiRequest.SAVE_QUICK, data);
    else return await api.post(ApiRequest.SAVE_SECOND_OPINION, data);
    
    

  },
  async cancelRequest(data: ICancelRequestParam){
    return await api.post(ApiRequest.CANCEL_REQUEST, data)
  }
}
export default RequestService;