import { useStore } from "@/store";
import { IFileListItem } from "./file";
import { RequestKindTypes, RequestStatuses } from "./request";

/**
 * 예약일, 예약시간, 의사, 주증상, 파일, 약국, 예약코멘트
 */
 export interface IOnlineCarePurpose {
  date:String,
  time:String,
  doctorKey :  Number | null,
  other: string,
  files: Array<IFileListItem> | null,
  pharmacyKey: Number | null
  otherComment? : String,
}
export interface IOnlineCarePurposeQuick {
  other: string,
  files: Array<IFileListItem> | null,
  otherComment? : String,
}

export class OnlineCare{
  public PID:string = "";
  public FacilityKey:number | null = null;
  public RequestKey:number | null = null;
  public CultureLangName="";
  public RequestKind:RequestKindTypes | null = null;
  public RequestPurpose = "";
  public PurposeOthers = "";
  public DesiredDrKey: number | null = null;
  public DesiredDateTime1: string ="";
  public SaveMode = 0;
  public RequestStatus:RequestStatuses = 0;
  public PharmacyKey:number | null = null;


  constructor(data:{datetime:string, other:string, doctorkey:number, requestKey:number, pharmacyKey:number|null}){
    const accountInfo = useStore().getters.getAccountInfo;
    // const locale = useI18n().locale;
    if(!accountInfo) return;
    this.PID = accountInfo.PID;
    this.FacilityKey = accountInfo.FacilityKey;
    // this.CultureLangName = locale.value;
    this.RequestKind = RequestKindTypes.OnlineCare;
    this.DesiredDateTime1 = data.datetime;
    this.PurposeOthers = data.other;
    this.DesiredDrKey = data.doctorkey;
    this.RequestKey = data.requestKey;
    this.PharmacyKey = data.pharmacyKey;
  }
}



export class OnlineCareQuick{
  public PID:string = "";
  public FacilityKey:number | null = null;
  public RequestKey:number | null = null;
  public CultureLangName="";
  public RequestKind:RequestKindTypes | null = null;
  public RequestPurpose = "";
  public PurposeOthers = "";
  public SaveMode = 0;
  public RequestStatus:RequestStatuses = 0;


  constructor(data:{ other:string,requestKey:number, }){
    const accountInfo = useStore().getters.getAccountInfo;
    // const locale = useI18n().locale;
    if(!accountInfo) return;
    this.PID = accountInfo.PID;
    this.FacilityKey = accountInfo.FacilityKey;
    this.RequestKind = RequestKindTypes.OnlineCare;
    this.PurposeOthers = data.other;
    this.RequestKey = data.requestKey;    
  }
}