
export enum PossibleTypes {
  ALL = 1,
  ONLINE_CARE = 2,
  SECOND_OPINION = 3
}
export interface IDcotorListItem{
  DeptKey:Number,
  DeptName:String,
  DoctorKey:Number,
  DoctorName:String,
  FacilityKey:Number,
  FacilityName: String,
  Profiles: String,
  ScheduledDays: String,
  MyDoctor:number,
  Image?:any
  ImagePath?:any
}

/**
 * doctorList: ALL
 * doctorListSplit: page로 분리
 * selectedDoctor: 선택한 의사
 * selectedPage: 선택한 페이지
 * displayDoctorRow: 페이지당 표시되는 의사 수
 */
export interface IDoctor {
  /** 전체 리스트 */
  doctorList: Array<IDcotorListItem>,
  /** 페이지 */
  doctorListSplit: Array<Array<IDcotorListItem>>,
  /** 선택한 의사 */
  selectedDoctor: Array<IDcotorListItem>,
  /** 선택한 페이지 */
  selectedPage: Number,
  /** 페이지당 표시되는 의사 수, 0이면 페이지 사용 안함 */
  displayDoctorRow:Number
}

export interface ISaveMyDoctorInfo{
  DoctorKey: number,
  MyDoctor: number
}

export interface IDepts{
  DeptKey: Number, 
  DeptName: String,
}