import { IResponseData } from "@/models/common";
import { IDcotorListItem, IDoctor, ISaveMyDoctorInfo, PossibleTypes } from "@/models/doctor";
import { RequestKindTypes } from "@/models/request";
import { AxiosResponse } from "axios";
import api from "../api/api";
import { ApiDoctor } from "./";

export const getDoctorList= async (possibleTypes : PossibleTypes) => {
  const doctors:IDoctor = {} as IDoctor
  const res: AxiosResponse<IResponseData<IDcotorListItem>> =
    await DoctorService.getDoctorList(possibleTypes);
  const {
    data: { ResultData },
  } = res;
  if (!Array.isArray(ResultData)) return [];

  doctors.doctorList = ResultData

  doctors.doctorList.sort((p: IDcotorListItem, n: IDcotorListItem) => {
    return n.MyDoctor - p.MyDoctor;
  });

  //의사 이미지 가져오기
  await Promise.all(
    doctors.doctorList.map(async (doctor: IDcotorListItem) => {
      const res = await DoctorService.getDoctorProfileImage(
        doctor.DoctorKey.toString()
      );
      doctor.Image = URL.createObjectURL(res.data);
    })
  ); 

  return doctors
};

export const getDoctor = async (key:string) => {
  const res: AxiosResponse<IResponseData<IDcotorListItem>> =
    await DoctorService.getDoctor(
      key
    );
  const {
    data: { ResultData }
  } = res;
  if (!!ResultData && !Array.isArray(ResultData)) {
  const imgRes = await DoctorService.getDoctorProfileImage(
    key
  );
    ResultData.Image = URL.createObjectURL(imgRes.data);

    return ResultData;
  }
  return {} as IDcotorListItem;
};

export const DoctorService = {
  async getDoctorList(Possible:null | PossibleTypes){
    
    if(!Possible) Possible = PossibleTypes.ALL;

    const params = {
      PossibleType: Possible  
    };
    return await api.get(ApiDoctor.DOCTOR_LIST, {params});
    // return await (await axios.get('/demo/data/doctors.json')).data.data;

  },async getScheduled(doctorkey:Number, requestKind: RequestKindTypes){
    const params = {
      doctorkey, requestKind
    }
    return await api.get(ApiDoctor.DOCTOR_SCHEDULEDS, {params});

  },
  async getDoctorProfileImage(key:String){
    const params = {
      key
    }
    return await api.get(ApiDoctor.DOCTOR_PROFILE_IMAGE, {params, responseType:'blob'});
  },
  async SaveMyDoctorInfo(data: ISaveMyDoctorInfo){
    return await api.post(ApiDoctor.SAVE_MY_DOCTOR_INFO, data);
  }
  ,
  async getDeptsList(){
    return await api.get(ApiDoctor.DEPTS_LIST);
  },
  async getDoctor(key:String){
    const params = {key};
    return await api.get(ApiDoctor.DOCTOR, {params});
  }
}
export default DoctorService;