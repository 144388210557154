import i18n from "@/i18n";
import {RouterName} from "@/models/common"
const t = i18n.global.t;

const onlineCareRequest = process.env.NODE_ENV === 'production' ? import('../views/onlinecare/OnlineCareRequest.vue') : import('../views/onlinecare/OnlineCareRequest4Type.vue')

/**
 * meta
 * title => document.title
 * class => main__section에 추가
 */
export const routes = 
[
  {
    path:process.env.BASE_URL,
    component: () => import('../layout/DefaultLayout.vue'),
    children:[       
      {            
        path:'',
        name:RouterName.HOME,
        component: () => import('../views/home/Home.vue'),
        meta:{class:"home"},
      },
      // not found
      {
        path: '/:pathMatch(.*)*',
        name: RouterName.NOTFOUND,
        component: () => import( '../views/NotFound.vue'),
        meta:{title:`${t("Page not found")} | HESEL CARE`},
      },
      {
        path:'onlinecare',            
        component: () => import('../layout/GuardLayout.vue'),
        meta:{class:"online-care"},
        children:[
          // Online Care
          {
            path:'',
            name: RouterName.ONLINE_CARE,
            component: () => import('../views/onlinecare/OnlineCare.vue'),                
          },              
          {
            path:'request',
            name:RouterName.ONLINE_CARE_REQUEST_NEW,
            //component: () => import('../views/onlinecare/OnlineCareRequest.vue'),
            component:()=>onlineCareRequest
            
          },
          {
            path:'request/:id',
            name:RouterName.ONLINE_CARE_REQUEST_VIEW,            
            component: () => import('../views/onlinecare/OnlineCareRequestView.vue'),
            props:true,
            
          },
          {
            path:'request/:id/edit',
            name:RouterName.ONLINE_CARE_REQUEST_EDIT,
            component: () => import('../views/onlinecare/OnlineCareRequestEdit.vue'),
            props:true,
            
          },
          {
            path:'response/:id',
            component: () => import('../views/onlinecare/OnlineCareResponse.vue'),
            props:true,
            name:RouterName.ONLINE_CARE_RESPONSE,
            
          },
          {
            path:'px/:id',
            component: () => import('../views/onlinecare/OnlineCarePX.vue'),
            props:true,
            name:RouterName.ONLINE_CARE_PX,
            meta:{class:"online-care-px"},
          },
          {
            path:'video/:id',
            component: () => import('../views/onlinecare/OnlineCareVideo.vue'),
            props:true,
            name:RouterName.ONLINE_CARE_VIDEO,
            meta:{title:`${t("Video")} | HESEL CARE`, class:"online-care-video"},
          },
        ]
      },

      //Second Opinion
      {
        path:'secondopinion',            
        component: () => import('../layout/GuardLayout.vue'),
        meta:{class:"second-opinion"},    
        children:[
          {
          path:'',
          name:RouterName.SECOND_OPINION,
          component: () => import('../views/secondopinion/SecondOpinion.vue'),              
          },        
          {
            path:'request',
            name:RouterName.SECOND_OPINION_REQUEST_NEW,
            component: () => import('../views/secondopinion/SecondOpinionRequest.vue'),
            
          },
          {
            path:'request/:id',
            name:RouterName.SECOND_OPINION_REQUEST_VIEW,            
            component: () => import('../views/secondopinion/SecondOpinionRequestView.vue'),
            props:true,
            
          },
          {
            path:'request/:id/edit',
            name:RouterName.SECOND_OPINION_REQUEST_EDIT,
            component: () => import('../views/secondopinion/SecondOpinionRequestEdit.vue'),
            props:true,
            
          }
          ,{
            path:'response/:id',
            component: () => import('../views/secondopinion/SecondOpinionResponse.vue'),
            props:true,
            name:RouterName.SECOND_OPINION_RESPONSE,
            
          },
        ]        
      },

      // legal
      {
        path: 'legal',
        name:RouterName.LEGAL,
        component:()=> import('../views/legal/Legal.vue'),
        props:true,
        meta:{class:'legal'},        
      },
      // myPage
      {
        path:'test',
        component: () => import('../views/test.vue')
      },
      // myPage
      {        
        path:'mypage',
        component: () => import('../layout/MypageLayout.vue'),
        meta:{ class:"my-page"},
        children:[
          {
            path:'profile',
            name:RouterName.PROFILE,
            component: () => import('../views/myPage/UserInfo.vue'),
            props:true
          },
          {
            path:'password',
            name:RouterName.PASSWORD,
            component: () => import('../views/myPage/Password.vue')
          },
          {
            path:'orderlist',
            name:RouterName.ORDER_LIST,
            component: ()=>import('../views/myPage/OrderList.vue')
          },
          {
            path:'mydoctor',
            name:RouterName.MY_DOCTOR,
            component: ()=>import('../views/myPage/MyDoctor.vue')
          }
          ,          
        ]
      },
      //Account      
      {
        path:'signup',
        name: RouterName.SIGNUP,
        component: () => import('../views/account/SignUp.vue'),
        meta:{title:`${t("signup")} | HESEL CARE`},

      },
      {
        path:'registrationcompleted',
        name: RouterName.REGISTRATION_COMPLETED,
        component: () => import('../views/account/RegistrationCompleted.vue'),
        props:true,
        meta:{title:`${t("registrationcompleted")} | HESEL CARE`},
      },
      {
        path:'ResetPasswordCompleted',
        name: RouterName.RESET_PASSWORD_COMPLETED,
        component: () => import('../views/account/ResetPasswordCompleted.vue'),
        props:true,
        meta:{title:`${t("resetPasswordCompleted")} | HESEL CARE`},
      },
      {
        path:'forgotpassword',
        name:RouterName.FORGOT_PASSWORD,
        component: () => import('../views/account/ForgotPassword.vue'),
        meta:{title:`${t("forgotPassword")} | HESEL CARE`},

      },
      {
        path:'confirmemail',
        name:RouterName.CONFIRM_EMAIL,
        component: () => import('../views/account/ConfirmEmail.vue'),
        props:true,
        meta:{title:`${t("confirmemail")} | HESEL CARE`},

      },
      {
        path:'resetpassword',
        name:RouterName.RESET_PASSWORD,
        component: () => import('../views/account/ResetPassword.vue'),
        meta:{title:`${t("resetpassword")} | HESEL CARE`},

      },      

      // manager
      {
        path: 'filemanager',            
        component: () => import('../layout/GuardLayout.vue'),
        children:[
          {
            path: ':id',
            name:RouterName.FILE_MANAGER,
            component: () => import( '../views/manager/FileManager.vue'),
            props:true,
            meta:{name:t("FileManager").toString()},

          },
          {
            path: 'px/:id',
            name:RouterName.FILE_MANAGER_PX,
            component: () => import( '../views/manager/FileManager.vue'),
            props:true,
            meta:{name:t("prescription").toString()},

          },
        ]

      },
      //order page
      {
        path: 'order',
        component: () => import( '../layout/AppLayout.vue'),
        children:[          
          {
            path:'orderview/:id',
            name:RouterName.ORDER_VIEW,
            component: ()=>import('../views/order/OrderView.vue')
          },
          {
            path:'ordercheckout',
            name:RouterName.ORDER_CHECKOUT,
            component: ()=>import('../views/order/OrderCheckout.vue')
          }
        ]
      },   
    ]
  },  
  {
    path:'/signin',
    name: RouterName.SIGNIN,
    component: () => import('../views/account/SignIn.vue'),
    meta:{title:`${t("signin")} | HESEL CARE`},
  },
  
  
];