import { IAccountInfo } from '@/models/account';
import { RootState } from '@/store';
import { GetterTree } from 'vuex'
import { State } from './state'

export type AuthGetters = {
  getAuthToken(state: State): string | null;
  getAccountInfo(state: State): IAccountInfo | null;
}

export const getters: GetterTree<State, RootState> & AuthGetters = {
  getAuthToken: (state) => {return state.token || "!!!"},
  getAccountInfo: (state) => {
    return {
      UserId:state.uid, 
      UserName:state.userName, 
      RoleName:state.role,
      Name:state.name,
      FacilityKey: state.facilityKey,
      PID:state.pid,
      IsRRN:state.isRRN
    }
  }
}
