import { messageType } from "./message";
import { RequestStatuses } from "./request";


export enum RouterName{
  ONLINE_CARE = 'OnlineCare',
  ONLINE_CARE_REQUEST_NEW = 'OnlineCareRequestNew',
  ONLINE_CARE_REQUEST_EDIT = 'OnlineCareRequestEdit',
  ONLINE_CARE_REQUEST_VIEW = 'OnlineCareRequestView',
  ONLINE_CARE_RESPONSE = 'OnlineCareResponse',
  ONLINE_CARE_VIDEO = 'OnlineCareVideo',
  ONLINE_CARE_PX = 'OnlineCarePX',
  SECOND_OPINION = 'SecondOpinion',
  SECOND_OPINION_REQUEST_NEW = 'SecondOpinionRequestNew',
  SECOND_OPINION_REQUEST_EDIT = 'SecondOpinionRequestEdit',
  SECOND_OPINION_REQUEST_VIEW = 'SecondOpinionRequestView',
  SECOND_OPINION_RESPONSE = 'SecondOpinionResponse',
  LEGAL = 'Legal',
  PRIVACY = 'Privacy',
  AGREEMENT = 'Agreement',
  NOTFOUND = 'Notfound',
  PROFILE = 'Profile',
  PASSWORD = 'Password',
  WITHDRAWAL = 'Withdrawal',
  MY_DOCTOR = 'MyDoctor',
  DOCTOR_ONLINE_CARE='DoctorOnlineCare',
  FILE_MANAGER = 'FileManager',
  FILE_MANAGER_PX = 'FileManagerPX',
  SIGNIN = 'Signin',
  SIGNUP = 'Signup',
  HOME='Home',
  //MY_PAGE='MyPage',
  REGISTRATION_COMPLETED='RegistrationCompleted',
  RESET_PASSWORD_COMPLETED='ResetPasswordCompleted',
  FORGOT_PASSWORD = 'ForgotPassword',
  CONFIRM_EMAIL = 'ConfirmEmail',
  RESET_PASSWORD = 'ResetPassword',
  ORDER_LIST = 'OrderList',
  ORDER_VIEW = 'OrderView',
  ORDER_CHECKOUT = 'OrderCheckout',
}

export enum InputTypes {
  TEXT = 'text',
  DATE = 'date',
  TEXTAREA = 'textarea'
}

export interface IInputs{
  type:InputTypes
  title?:String
}


export enum SEX{
  F="F",
  M="M",
  O="O"
}
// export interface IUserInfo{
//   userName:String,
//   email:String,
//   phoneNumber:String,
//   surname:String,
//   givenName:String,
//   sex:SEX,
//   birthDate:String,
//   isAllowingEmail:Boolean,
//   isAllowingSMS:Boolean,
// }

export enum StatusTexts{
  OK="OK",
}

export enum ExceptionMessages{
  FAILED="failed"
}

export enum ResultDatas{
  SUCCESS="success"
}
export interface IResponseData<T>{
  Exception: any,
  ResultCode : Number,
  ResultData: Array<T> | T
}

export interface IDataNameFilter{
  from:string,
  to:string,
  name:string
}

export interface IReqScheduleds{
  RequestKey:String,
  RequestStatus:RequestStatuses,
  DoctorKey:Number,
  ScheduledDatatime:String
}
export interface IDoctorScheduled{
  DoctorScheduledDays: String,
  ReqScheduleds: Array<IReqScheduleds>
}

/**
 * 오전, 오후
 */
export interface ITimeList{
  am:Array<string>,
  pm:Array<string>
}

export interface IAlert{
  type:messageType,  
  title?:String | null,
  content:String,
  callback?:Function | null
}