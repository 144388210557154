import api from "@/api/api";
import { ISignInfo } from "@/models/account";
import { IResponseData } from "@/models/common";
import { ISignupInfo } from "@/models/sign";
import { AxiosResponse } from "axios";
import { ApiSign } from "./";

export interface SigninParams{
  email:string,
  password:string
  rememberMe?:boolean
}

export const SignService = {
  async signin(params:SigninParams | null):Promise<AxiosResponse<IResponseData<ISignInfo | string>>> {
    return params ? await api.post(ApiSign.SIGN_IN, params) : await api.get(ApiSign.GET_TOKEN);
  },

  async withdraw(password:string):Promise<AxiosResponse>{
    const params = {password};
    return await api.post(ApiSign.WITHDRAW, params);
  }, 
  async signup(signupInfo:ISignupInfo):Promise<AxiosResponse>{
    return await api.post(ApiSign.SIGN_UP, signupInfo);
  },
  async checkPossibleEmail(email:string):Promise<AxiosResponse>{
    const params = {email}
    return await api.get(ApiSign.Check_Possible_Email,{params});
  },
  async forgetPassword(email: string):Promise<AxiosResponse>{
    const params = {email};
    return await api.post(ApiSign.FORGOT_PASSWORD, params);
  },
  async resetPassword(email: string, password: string, code:string):Promise<AxiosResponse>{
    const params = {email, password, code};
    return await api.post(ApiSign.RESET_PASSWORD, params);
  },
  async confirmEmail(userId: string, code:string):Promise<AxiosResponse>{
    const params = {userId, code};
    return await api.post(ApiSign.CONFIRM_EMAIL, params);
  },
  async signout():Promise<AxiosResponse>{
    return await api.get(ApiSign.SIGN_OUT);
  },
  async getToken():Promise<AxiosResponse<IResponseData<ISignInfo | string>>>{
    return await api.get(ApiSign.GET_TOKEN);
  },
  async changePassword(oldPassword:string, newPassword:string):Promise<AxiosResponse>{
    const params = {newPassword, oldPassword}
    return await api.post(ApiSign.CHANGE_PASSWORD, params);
  },
}

export default SignService;