import { IResponseData } from "@/models/common";
import { IFileListItem } from "@/models/file";
import { AxiosResponse } from "axios";
import api from "../api/api";
import { ApiFileManager } from "./";

export enum DataType{
  DICOM = "DICOM",
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  PASSPORT = "PASSPORT",
  IDCARD = "IDCARD" 
}

export enum FileType{
  PDF = "PDF",
  JPG = "JPG",
  ZIP = "ZIP"
}

const getFile = async (key:string)=>{
  const params = {
    reftab:"request",
    refkey:key
  }
  const res: AxiosResponse<IResponseData<IFileListItem>> = await api.get(ApiFileManager.GET_FILE_LIST, {params});
  const {
    data: { ResultData },
  } = res;
  if (!Array.isArray(ResultData)) return [];
  else return ResultData;
}

export const FileService = {
  async getFile(key:string){    
    // const params = {
    //   reftab:"request",
    //   refkey:key
    // }
    // return await api.get(ApiFileManager.GET_FILE_LIST, {params});
    const result = await getFile(key);
    return result;    
  },

  async getStudyKey(fileKey:string){
    const params = {
      fileKey
    }
    return await api.get(ApiFileManager.GET_STUDY_KEY, {params});
  },
  
  getFilePath(filePath:string){
    return `/${process.env.VUE_APP_SUB_URL}/Uploads/${filePath}`;
  },
  getFileDownLoadPath(filePath:string){
    return `/Uploads/${filePath}`;
  },

  getPdfPath(filePath:string){
    // console.log(api.defaults.baseURL + "Uploads/" + filePath)    
    return `${process.env.BASE_URL}lib/pdfjs-2.6.347-dist/web/viewer.html?file=/${process.env.VUE_APP_SUB_URL}/Uploads/${filePath}`;
  },

  async downloadFile(filePath:string, title:string){
    const res = await api.get(filePath, {responseType:'blob'});
    const fileURL = URL.createObjectURL(res.data);
      const fileLink = document.createElement("a");

      fileLink.setAttribute("download", title);
      fileLink.href = fileURL;
      fileLink.click();
  },

  async deleteFile(fileKey:number){
    const params = {fileKey}
    return await api.get(ApiFileManager.DELETE_FILE, {params});
  }
}
export default FileService;