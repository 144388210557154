import { ActionTree, ActionContext } from 'vuex'
import { State } from './state'
import { Mutations, AuthMutationTypes } from './mutations'
import { RootState } from '@/store'
// import { SingService } from '@/api/sing'
import { SigninParams } from '@/services/sign'
import { AxiosResponse } from 'axios'
import $API from "@/services";
import { ExceptionMessages, IResponseData, StatusTexts } from '@/models/common'
import { IAccountInfo, ISignInfo } from '@/models/account'
import router from '@/router'

export enum AuthActionTypes {
  GET_TOKEN = 'GET_TOKEN',
  GET_ACCOUNT_INFO ='GET_ACCOUNT_INFO',
  SIGNOUT = 'SIGNOUT'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AuthActionTypes.GET_TOKEN](
    { commit }: AugmentedActionContext,
    payload: SigninParams | null
  ):Promise<void | string>,
  [AuthActionTypes.GET_ACCOUNT_INFO](    { commit }: AugmentedActionContext):Promise<void>
  [AuthActionTypes.SIGNOUT](    { commit }: AugmentedActionContext):Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AuthActionTypes.GET_TOKEN]({ commit }, payload) {
    // const signinParam:SigninParams = {email:'aa', password:'bb'}
    const res:AxiosResponse<IResponseData<ISignInfo | string>> = await $API.SignService.signin(payload);
    const {data} = res;
    if(data.Exception === ExceptionMessages.FAILED){
      return data.ResultData as string;
    }
    else commit(AuthMutationTypes.SET_TOKEN, res);
  },
  async [AuthActionTypes.GET_ACCOUNT_INFO]({commit}){
    const res:AxiosResponse<IResponseData<IAccountInfo>> = await $API.AccountsService.getAccountInfo();
    commit(AuthMutationTypes.SET_INFO, res);    
  },
  async [AuthActionTypes.SIGNOUT]({commit}){
    commit(AuthMutationTypes.CLEAR_TOKEN);
    const res: AxiosResponse = await $API.SignService.signout();
      if (res.statusText === StatusTexts.OK || res.status === 200) {
        router.push(process.env.BASE_URL);
      }
  }
}
