import { App } from "vue";


export default{
  install:(app: App) =>{
    const dev = {
      isDev:()=>{
        return process.env.NODE_ENV !== 'production';
      }
       
    }
    
    app.directive('dev', {
      mounted(el:HTMLElement){
        if(!dev.isDev()) el.remove();
      }
    })
  }
}