
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "Srcoll",
  setup() {
    const isScroll = ref(true);
    const handleScroll = () => {
      isScroll.value =
        document.scrollingElement?.scrollTop === 0 ? true : false;
    };
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return { isScroll };
  },
});
