import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';

import './styles/index.scss'
import i18n from './i18n'
import GlobalValidators from "@/utils/GlobalValidators";
import ConfirmationService from 'primevue/confirmationservice';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPrescription, faUserCog, faSignOutAlt, faUserPlus, faSignInAlt, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import setRoutesInfo from './plugins/display';
import checkAccount from './plugins/checkAccount';
import dev from "./plugins/dev";
import Alert from "@/components/common/Alert";

library.add(faPrescription, faSignOutAlt, faUserCog, faUserPlus, faSignInAlt, faChevronUp, faChevronDown );

GlobalValidators();

createApp(App)
.use(i18n)
.use(store)
.use(router)
.use(PrimeVue)
.use(ConfirmationService)
.use(setRoutesInfo)
.use(checkAccount)
.use(dev)
.component('Alert', Alert)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
