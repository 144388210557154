// import { RouterName } from '@/models/common';
import { useStore } from '@/store';
import { createRouter, createWebHistory  } from 'vue-router'
import {routes} from './routes'


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(){
    return {
      top: 0,
      left: 0
    }
  }
});


router.beforeEach((to, from, next)=>{
  
  //토큰 확인
  // 토큰 가져오기
  const store = useStore();
  const accountInfo = store.getters.getAccountInfo;  

  //유저 아니면
  const role = store.getters.getAccountInfo?.RoleName;  

  if (!!role && role !== "Users" && process.env.NODE_ENV === "production") {
    window.location.href = `/${process.env.VUE_APP_SUB_URL}`;
  }

  // //로그인 필요없는 경우
  // if(
  //   to.name === RouterName.RESET_PASSWORD || 
  //   to.name === RouterName.CONFIRM_EMAIL || 
  //   to.name === RouterName.HOME || 
  //   to.name === RouterName.SIGNIN || 
  //   to.name === RouterName.SIGNUP || 
  //   to.name === RouterName.RESET_PASSWORD || 
  //   to.name=== RouterName.RESET_PASSWORD_COMPLETED){
  //   next();
  // }

  //로그인 필요한 경우
  // else if(
  //   to.name === RouterName.ONLINE_CARE_REQUEST_NEW || 
  //   to.name === RouterName.ONLINE_CARE_REQUEST_EDIT || 
  //   to.name === RouterName.ONLINE_CARE_PX ||
  //   to.name === RouterName.ONLINE_CARE_REQUEST_VIEW ||
  //   to.name === RouterName.ONLINE_CARE_RESPONSE ||
  //   to.name === RouterName.ONLINE_CARE_VIDEO ||
  //   to.name === RouterName.SECOND_OPINION_REQUEST_EDIT || 
  //   to.name === RouterName.SECOND_OPINION_REQUEST_NEW ||
  //   to.name === RouterName.SECOND_OPINION_REQUEST_VIEW ||
  //   to.name === RouterName.SECOND_OPINION_RESPONSE
  //   ){
    
    
    //로그인 되어있고 최초이용 아닌 경우 -> 이동
    else if(!!accountInfo?.RoleName && accountInfo.RoleName === "Users"){
      next()
    }       
  //   else {
  //     const path = {name:RouterName.SIGNIN,query:{redirect:to.path} };
  //     next(path);       
  //   }
  // }
  //로그인이 필요없는 경우
  else {
    next()
  }
    
});

          
export default router;