import api from "@/api/api";
import { IMyProfile } from "@/models/account";
import { IResponseData } from "@/models/common";
import { AxiosResponse } from "axios";
import {ApiAccounts} from '.'
export const AccountsService = {
  /**
   * 
   * @returns 마이페이지에서 설정한 약국키
   */
  async getMyDefaultPharmacyKey() {
    return await api.get(ApiAccounts.GET_DEFAULT_PHARMACYKEY);
  },

  async getMyProfile() {
    // return await api.get(ApiAccounts.USER_PROFILE);
    const res: AxiosResponse<IResponseData<IMyProfile>> = await api.get(ApiAccounts.USER_PROFILE);
    const {
      data: { ResultData },
    } = res;
    if (Array.isArray(ResultData)) return null;

    const profile:IMyProfile = ResultData;
    
    profile.ReceiveEmail = !!profile?.ReceiveEmail;
    profile.ReceiveSms = !!profile?.ReceiveSms;

    const { BirthDate, PhoneNumber, Sex } = profile;
    if (!(BirthDate && PhoneNumber && Sex)) {
      return profile;
    } else {
      if (profile.BirthDate.length === 8) {
        profile.BirthDate =
          profile.BirthDate.substring(0, 4) +
          "/" +
          profile.BirthDate.substring(4, 6) +
          "/" +
          profile.BirthDate.substring(6, 8);
      }
    }
    return profile;
  },
  async getAccountInfo(){
    return await api.get(ApiAccounts.ACCOUNT_INFO);
  },
  /***
   * @param culture : "en-us", "ja-jp", "ko-kr";
   */
  async setLanguage(culture:string){
    const params = {
      culture
    }
    return await api.get(ApiAccounts.SET_LANGUAGE, {params})
  },
  async saveMyProfileData(data:IMyProfile){
    data.ReceiveEmail = data.ReceiveEmail ? 1 : 0;
    data.ReceiveSms = data.ReceiveSms ? 1 : 0;
    data.PharmacyKey= data.PharmacyKey??0;

    return await api.post(ApiAccounts.SAVE_MY_PROFILE, data);
  },
  /**
   * 
   * @param rrn 주민등록번호
   * @returns 
   */
  async setResidentRegNumber(rrn: string){    
    return await api.post(ApiAccounts.SAVE_RRN, {rrn});
  }
}

export default AccountsService