import { defineRule, configure } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';


const GlobalValudators=()=>{
  defineRule('required', required);  
  defineRule('email',email);    
  defineRule('confirmed', (value:string, [target]:Array<string>) => {
    if (value === target) {
      return true;
    }
  
    return false;
  });
  
  defineRule('birthdate', (value:string)=>{
    const reg = /^(19[0-9][0-9]|20\d{2})\/(0[0-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/
    if(value.match(reg))
      return true;
    return false;
  })

  defineRule('rrn1', (value:string)=>{
    const reg = /([0-9]){6}/g;
    if(value.match(reg))
      return true;
    return false;
  })

  defineRule('rrn2', (value:string)=>{
    const reg = /([0-9]){7}/g;
    if(value.match(reg))
      return true;
    return false;
  })
  defineRule('password', (value:string)=>{
    const reg = /(?=.*[a-zA-Z])(?=.*[!@#$%^&+=])([^\s]){8,20}$/g;
    if(value.match(reg))
      return true;
    return false;
  })
  configure({
    generateMessage: localize(messages),
  });
  defineRule('emailName', (value:string)=>{
    // eslint-disable-next-line no-useless-escape
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))/;
    return value.match(reg) ? true  : false
  })

  defineRule('emailProvider', (value:string)=>{
    // eslint-disable-next-line no-useless-escape
    const reg = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value.match(reg) ? true  : false
  });
}

const messages= {
  en: {
    messages: {
      required: '*',
      email:'ex>xxx@xxx.xxx',
      password:'not rule',
      confirmed: 'not math',
      birthdate:'YYYY/MM/DD'
    },
  },
  ko: {
    messages: {
      required: '*',
      email:'ex>xxx@xxx.xxx',
      password:'8자리이상, 숫자, !@#$%^&+= 중 1개 포함',
      confirmed: '비밀번호와 비밀번호확인 불일치',
      birthdate:'YYYY/MM/DD'
    },
  },      
  ja: {
    messages: {
      required: '*',
      email:'ex>xxx@xxx.xxx',
      password:'not rule',
      confirmed: 'not math',
      birthdate:'YYYY/MM/DD'
    },
  },
}

export default GlobalValudators;

