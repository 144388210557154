import api from '@/api/api'
import {DoctorService} from './doctor'
import {FileService} from './file'
import {RequestService} from './request'
import {SignService} from './sign'
import {StudyService} from './study'
import AccountsService from './account'
import PharmacyService from './pharmacy'
import TextChatService from './textChat'

export enum ApiDoctor{
  DOCTOR = "/api/ApiDoctorsV2/Get",
  DOCTOR_LIST = "/api/ApiDoctorsV2/GetList",
  DOCTOR_PROFILE_IMAGE = "/api/ApiDoctorsV2/GetProfilePictureFile",
  DOCTOR_SCHEDULEDS = "/api/ApiDoctorReferV2/GetScheduleds",
  SAVE_MY_DOCTOR_INFO = "/api/ApiDoctorsV2/SaveMyDoctor",
  DEPTS_LIST = "/api/ApiDepts/GetList"
}

export enum ApiFileManager{
  GET_FILE_LIST = '/api/ApiFileManagerV2/GetList',
  GET_STUDY_KEY = '/api/ApiFileManagerV2/GetStudyKeys',
  DELETE_FILE = '/api/ApiFileManagerV2/Delete',
}


export enum ApiRequest{
  REQUEST_LIST = '/api/ApiRequestListV2/GetMyList',
  GET_REQUEST = '/api/ApiRequestV2/Get',
  REQUEST_LIST_ITEM = '/api/ApiRequestListV2/GetMyItem',
  GET_REQUEST_RESPONSE = '/api/ApiRequestResponseV2/GetResponse',
  GET_REQUEST_SUMMARY = '/api/ApiRequestV2/RequestSummary',
  SAVE = '/api/ApiRequestV2/Save',
  SAVE_QUICK = '/api/ApiRequestV2/SaveQuick',
  SAVE_SECOND_OPINION = '/api/ApiRequestV2/SaveSecondOpinion',
  CANCEL_REQUEST = '/api/ApiRequestV2/CancelRequest',
}

export enum ApiSign {
  SIGN_IN = '/api/ApiSign/Signin',
  SIGN_UP = '/api/ApiSign/Signup',
  Check_Possible_Email = '/api/ApiSign/CheckPossibleEmail',
  WITHDRAW = '/api/ApiSignV2/Withdraw',
  CHANGE_PASSWORD = '/api/ApiSignV2/ChangePassword',
  FORGOT_PASSWORD = '/api/ApiSign/ForgotPassword',
  RESET_PASSWORD = '/api/ApiSign/ResetPassword',
  CONFIRM_EMAIL = '/api/ApiSign/ConfirmEmail',
  SIGN_OUT = '/api/ApiSignV2/SignOut',
  GET_TOKEN = '/api/ApiSignV2/GetToken'
}

export enum ApiStudyData{
  BASE64_STUDY_THUMBNAIL = '/api/ApiStudyDataV2/GetBase64StudyThumbnail',

}

export enum ApiAccounts{
  USER_PROFILE = '/api/ApiAccountsV2/GetMyProfileData',
  GET_DEFAULT_PHARMACYKEY = '/api/ApiAccountsV2/GetMyDefaultPharmacyKey',
  ACCOUNT_INFO = '/api/ApiAccountsV2/GetAccountInfo',
  SET_LANGUAGE = '/api/ApiAccountsV2/SetLanguage',
  SAVE_MY_PROFILE = '/api/ApiAccountsV2/SetMyProfileData',
  SAVE_RRN = '/api/ApiAccountsV2/SetResidentRegNumber',
}


export enum ApiPharmacy{
  GET_PHARMACIES = '/api/ApiPharmacy/GetPharmacyList',
}

export enum ApiTextChat{
  GET_LASTMESSAGE = '/api/apiTextChat/GetLastChatMsgs'
}

const $API = {
  DoctorService,
  FileService,
  RequestService,
  SignService,
  StudyService,
  AccountsService,
  PharmacyService,
  ApiPharmacy,
  TextChatService,

  BaseURL:api.defaults.baseURL
  
}

export default $API