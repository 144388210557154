import { RouterName } from "@/models/common";
import router from "@/router";
import { useStore } from "@/store";
import { App } from "vue";

export interface ICheckAccountInfo {
  checkAccountInfo:() => void,
  checkRRN:()=>void,
  checkPID:()=>void
}

export default{
  install:(app: App) =>{    
    const checkAccount:ICheckAccountInfo = {

      // 새로고침시 beforeEach가 app의 getAccountInfo보다 먼저 동작, 새로 고침시 로그인 여부 확인
      checkAccountInfo:()=>{
        const store = useStore();
        const accountInfo = store.getters.getAccountInfo;

        if(!accountInfo?.RoleName){
          const path = {name:RouterName.SIGNIN,query:{redirect:router.currentRoute.value.fullPath} };
          router.push(path)     
        }        
      },

      // 환자 정보, 최초이용
      checkPID:()=>{
        const store = useStore();
        const accountInfo = store.getters.getAccountInfo;
        if(!!accountInfo?.RoleName && !accountInfo?.PID){          
          const path = {name:RouterName.PROFILE }
          router.push(path);
        }    
      },
      //주민등록번호 저장여부
      checkRRN:()=>{
        const store = useStore();
        const accountInfo = store.getters.getAccountInfo;
        if((accountInfo?.IsRRN ?? 0) === 0){
          const path = {name:RouterName.PROFILE, params:{isRRN: 1}}
          router.push(path);        
        }
      }      
    }
    
    app.directive('Account', {
      mounted(){
        checkAccount.checkAccountInfo();
      }
    });
    app.directive('RRN', {
      mounted(){
        checkAccount.checkRRN();
      }
    });
    app.directive('PID', {
      mounted(){
        checkAccount.checkPID();
      }
    });
    
  }
}