import { RootState } from '@/store';
import { GetterTree } from 'vuex'
import { State } from './state'

export type CommonGetters = {
  getGlobalLoading(state: State): Boolean;
  getIsFileUpdate(state:State):Boolean;
}

export const getters: GetterTree<State, RootState> & CommonGetters = {
  getGlobalLoading: (state) => {
    return !!state.isLoadong
  },
  getIsFileUpdate: (state) => {
    return !!state.isFileUpdate
  }
}
