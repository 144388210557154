import { createStore } from 'vuex';
import {store as auth, AuthStore, State as AuthState} from './modules/auth'
import {store as common, CommonStore, State as CommonState} from './modules/common'


export type RootState = {
  auth: AuthState,
  common: CommonState
};

export type Store = AuthStore<Pick<RootState, 'auth'>> & CommonStore<Pick<RootState, 'common'>>;

// Plug in logger when in development environment
// const debug = process.env.NODE_ENV !== 'production';
// const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  // plugins,
  modules: {
    auth,
    common
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;