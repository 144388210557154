import { state } from './state'
import { CommonGetters, getters } from './getters'
import { Mutations, mutations } from './mutations'
import {
  Store as VuexStore,
  CommitOptions,
  Module,
} from 'vuex';
import type { State } from './state';
import { RootState } from '@/store';
  
export { State };

export type CommonStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof CommonGetters]: ReturnType<CommonGetters[K]>
  };
};

export const store: Module<State, RootState> = {
  state,
  mutations,
  getters,
};