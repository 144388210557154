import router from "@/router";
import { App } from "vue";

export interface ISetDisplay {
  setTitle:() => void
  setMainSectionClass:() => void
  setHeader:(b:boolean)=>void
  disableFooter:(b:boolean)=>void
  disableHeader:(b:boolean)=>void
  displayScroll:(b:boolean)=>void
}

export default{
  install:(app: App) =>{
    const setDisplay:ISetDisplay = {

      /**
       * document.title 지정
       */
      setTitle:()=>{
        const {title:newtitle} = router.currentRoute.value.meta;
        if(!!newtitle && typeof newtitle === 'string'){
          document.title = newtitle
        }
      },
      
      /**
       * main__section에 이름 지정
       */
      setMainSectionClass:()=>{
        const {class:className} = router.currentRoute.value.meta;
        if(!!className && typeof className === 'string'){
          const main = document.querySelector('section.main__section');
          main?.classList.remove(...main?.classList);
          main?.classList.add('main__section');
          main?.classList.add(className);
        }
      },

      /**
       * header 교체
       */
      setHeader:(bool:boolean)=>{
        const header = document.querySelector('header.hesel-care__header') as HTMLElement;
        const footer = document.querySelector('footer') as HTMLElement;
        const simpleHeader = document.querySelector('header.simple__header') as HTMLElement;
        if(bool){          
          header?.style.setProperty("display", "none");
          footer?.style.setProperty("display", "none");
          simpleHeader?.style.setProperty("display", "flex");          
        }
        else {
          header?.style.setProperty("display", "flex");
          footer?.style.setProperty("display", "flex");
          simpleHeader?.style.setProperty("display", "none");
        }
      },

      disableFooter:(bool:boolean)=>{
        if(bool){
          const footer$ = document.querySelector("footer");
          if (!footer$?.classList.contains("-none")) {
            footer$?.classList.add("-none");
          }
        }
        else{
          const footer$ = document.querySelector("footer");
          if (footer$?.classList.contains("-none")) {
            footer$?.classList.remove("-none");
          }
        }
      },
      disableHeader:(bool:boolean)=>{
        if(bool){
          const header$ = document.querySelector("header");
          if (!header$?.classList.contains("-none")) {
            header$?.classList.add("-none");
          }
        }
        else{
          const footer$ = document.querySelector("header");
          if (footer$?.classList.contains("-none")) {
            footer$?.classList.remove("-none");
          }
        }
      },
      displayScroll:(bool:boolean)=>{
        const scroll$ = document.getElementById('scroll-icon');
        if(bool){
          if(scroll$)
            scroll$.style.display="block"
        }
        else {
          if(scroll$)
          scroll$.style.display="none"
        }
      }
    }
    
    app.directive('title', {
      mounted(){
        setDisplay.setTitle();
      }
    });
    app.directive('name', {
      mounted(){
        setDisplay.setMainSectionClass();
      }
    });

    app.directive('simpleHeader', {
      mounted(){
        setDisplay.setHeader(true);
      },
      unmounted(){
        setDisplay.setHeader(false);
      }
    });

    app.directive('noneFooter', {
      mounted() {
        setDisplay.disableFooter(true)
      },
      unmounted() {
        setDisplay.disableFooter(false)
      }
    });
    app.directive('noneHeader', {
      mounted() {
        setDisplay.disableHeader(true)
      },
      unmounted() {
        setDisplay.disableHeader(false)
      }
    });  
    app.directive('scrollIcon', {
      mounted() {
        setDisplay.displayScroll(true)
      },
      unmounted() {
        setDisplay.displayScroll(false)
      }
    });  
  }
}