import { RouterName } from "./common";


/**
 * onliecare, secondopinion
 */
export enum RequestKindTypes {
  OnlineCare="tele",
  SecondOpinion="second"
}
export enum RequestTypes{
  NORMAL=0,
  QUICK=1,
  SELECT=2
}
export enum RequestStatuses {
  /**
   * 사용자 취소
   */
  Canceled=100,
  /**
   * 임사저장 사용 X
   */
  Preparing=0,
  /**
   * 의사에게 의뢰(예약신청)
   */
  Requested=1,
  /**
   * 의사 회신(예약 회신), 결제시스템 연동시 예약확정, 결제대기상태
   */
  Scheduled=7,
  /**
   * Closed
   */
  Completed=10, //회신 완료 종료 or 반려
}

export enum RequestLiveStatuses{
  All=0,
  Live=1,
  Terminated=2
}

export enum ResponseStatuses {
  Ready= 0,
  /**
   * 예약확정
   */
  Scheduled= 1,
  /**
   * 결과 회신
   */
  Complete= 2,
  /**
   * 반려
   */
  Refused= 3
}

export enum RequestSteps{
  ONE = 0,
  TWO = 1,
  THREE = 2
}

export interface IRequestListItem {
  RequestKey:Number,
  RequestStatus: RequestStatuses,
  ResponseStatus:ResponseStatuses,
  PatientInfo:String,
  InsertDateTime:String,
  RegFileCount:String,
  RegUserFileCount:String,
  QnaCount:String,
  RequestKind:RequestKindTypes,
  RequestPurpose:String,
  ReferredDrKey?:Number,
  ReferredDrName?:String,
  ReferredDrDeptName?:String,
  ReferredDrProfiles?:String,
  DesiredDrKey?:Number
  DesiredDrName?:String,
  DesiredDrDeptName?:String,
  DesiredDrProfiles?:String,
  DesiredDateTime1:String,
  ScheduledDateTime?:String,
  RespondDateTime?:String,
  RespondUserName?:String,
  TotalCallTime?:String,
  PatientKey:Number,
  IsDispense:Boolean,

  PID: String;
  FirstName?: String;
  LastName?: String;
  EFirstName?: String;
  ELastName?: String;
  Sex?: String;
  CountryCode?: String;
  BirthDate?: String;
  PhoneNumber?: String;
  Email?: String;
  CultureLangName?: String;
  PurposeOthers?: String;
  ChiefComplaint?: String;
  Diagnosis?: String;
  MedicalHistory?: String;
  Recommendation?: String;
  Medication?: String;
  AddInfoResp?: String;
  DesiredDate?: String;
  DesiredTime?: String;
  DesiredDateTime2?: String;
  OtherComments?: String;
  SaveMode?: Number;
  PName?: String;
  CountryName?: String;
  IsNew?: Boolean;
  PreFileCount?:Number
  type?:RequestListType;
  DocFacilityName?:String
  RequestType?:RequestTypes,
}

export interface IRequest{
  DesiredDate:String,
  DesiredTime:String,
  PID:String,
  DesiredDrKey:Number,
  ReferredDrKey: Number,
  RequestKey:Number,
  RequestKind:RequestKindTypes,
  RequestType?:RequestTypes,
  RequestStatus:RequestStatuses,
  ScheduledDateTime:String,
  DesiredDateTime1:String,
  SaveMode:String,
  PurposeOthers:String
  ChiefComplaint:String,
  PharmacyKey:Number | null
  OtherComments?:String
}

export interface IRequestResponse{
  PatInfo:String,
  DoctorInfo:String,
  ScheduledDateTime:String, 
  OtherComments:String,
  LabelText:String,
  Video:String,
  Opinions:String,
  ResponseDate:String,
  InsertDate:String,
  DoctorKey?:Number
}



export interface IRequestList{
  kind: RequestKindTypes,
  title: string,
  items?: Array<IRequestListItem>
  type: RequestListType
}

/**
 * Request, Response
 */
export enum RequestListType{
  "Request",
  "Response"
}


export interface IRequestSummary{
  RequestKind: RequestListType,
  Canceled: Number,
  Preparing: Number,
  Requested: Number,
  Scheduled: Number,
  Completed: Number
}

export interface ICancelRequestParam{
  RequestKey: Number
}

export interface IGetRequestListOptions {
  DateStart?:string,
  DateEnd?:string,
  RequestKinds: RequestKindTypes,
  IsLive: Number
}

interface IRequestCardButton {
  label: String,
  type: String,
  path: { 
    name: RouterName;
    params: {
        id: Number;
    }} | (()=>void),
    style: String
}
export interface IRequestCardInfo {
  state: String,
  message:String,
  style: String,
  buttons: Array<IRequestCardButton> |null
        
}