
import { defineComponent, ref } from "vue";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";

export default defineComponent({
  name: "Layer Popup",
  setup(props, { emit }) {
    const displayNone7Days = ref(true);

    /**
     * popup 닫기 click event
     */
    const close = () => {
      emit("close", displayNone7Days.value);
    };

    return { displayNone7Days, close };
  },
  components: {
    Checkbox,
    Button,
  },
});
