import api from '@/api/api'
import { IAccountInfo, ISignInfo } from '@/models/account'
import { IResponseData } from '@/models/common'
import { AxiosResponse } from 'axios'
import { MutationTree } from 'vuex'
import { State } from './state'

export enum AuthMutationTypes {
  SET_TOKEN = 'SET_TOKEN',
  SET_INFO = 'SET_INFO',
  CLEAR_TOKEN = "CLEAR_TOKEN"
}

export type Mutations<S = State> = {
  [AuthMutationTypes.SET_TOKEN](state: S, payload: AxiosResponse | string): void,
  [AuthMutationTypes.SET_INFO](state: S, payload: AxiosResponse | string): void,
  [AuthMutationTypes.CLEAR_TOKEN](state: S): void,
}

export const mutations: MutationTree<State> & Mutations = {
  [AuthMutationTypes.SET_TOKEN](state, payload:AxiosResponse<IResponseData<ISignInfo>> | string) {    
    let token="";
    if(typeof payload !== "string"){
      const {data:{ResultData}} = payload;
      if(!Array.isArray(ResultData)){
        const {accessToken} = ResultData;
        token = accessToken;
      }
    }
    else token = payload;
    state.token = token;
    localStorage.setItem("access", token);
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    
  },
  [AuthMutationTypes.SET_INFO](state, payload:AxiosResponse<IResponseData<IAccountInfo>>) {    
    const {data:{ResultData}} = payload;
    if(Array.isArray(ResultData))return ;
    else if(!ResultData) return;

    state.uid = ResultData.UserId;
    state.userName = ResultData.UserName;
    state.role = ResultData.RoleName;
    state.name = ResultData.Name;
    state.pid = ResultData.PID?ResultData.PID : "";
    state.isRRN = ResultData.IsRRN;
  },
  [AuthMutationTypes.CLEAR_TOKEN](state) {
    state.token="",
    state.uid = ""
    state.userName = "";
    state.role = "";
    state.name = "";
    state.pid = "";
    localStorage.removeItem("access");
    api.defaults.headers.common.Authorization = "";
  }
}
