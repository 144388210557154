
import { defineComponent, onMounted, ref } from "vue";
import LayerPopup from "@/components/common/LayerPopup.vue";
import Specifications from "@/components/common/Specifications.vue";
import Cookies from "js-cookie";

export default defineComponent({
  name: "App Layout", //App first
  setup() {
    const isCookie = ref(false);
    const close = (displayNone7Days: boolean) => {
      if (displayNone7Days) Cookies.set("popup", "1", { expires: 7 });
      isCookie.value = false;
    };
    onMounted(() => {
      isCookie.value = !Cookies.get("popup") ?? "";
    });
    return { close, isCookie };
  },

  components: {
    LayerPopup,
    Specifications,
  },
});
