import { MutationTree } from 'vuex'
import { State } from './state'

export enum CommonMutationTypes {
  SET_IS_LODING = 'SET_IS_LOADING',
  FILE_UPDATE = 'FILE_UPDATE'
}

export type Mutations<S = State> = {
  [CommonMutationTypes.SET_IS_LODING](state: S, payload: Boolean): void,
  [CommonMutationTypes.FILE_UPDATE](state: S, payload: Boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [CommonMutationTypes.SET_IS_LODING](state, payload:Boolean) {
    state.isLoadong = payload;
    
  },
  [CommonMutationTypes.FILE_UPDATE](state, payload:Boolean) {
    state.isFileUpdate = payload;
    
  },
}
