<template>
  <CDialog
    v-model:visible="visible"
    :modal="true"
    :header="header"
    :blockScroll="blockScroll"
    :position="position"
    class="p-confirm-dialog-custom"
    :breakpoints="breakpoints"
    @update:visible="
      (e) => {
        if (!e) {
          reject();
        }
      }
    "
  >
    <template #header v-if="$slots.title">
      <slot name="title"></slot>
    </template>
    <span v-if="$slots.message" class="p-confirm-dialog-message">
      <slot name="message" />
    </span>

    <span v-else class="p-confirm-dialog-message">{{ message }}</span>

    <div v-if="$slots.info" class="p-confirm-dialog-custom-info">
      <slot name="info" />
    </div>
    <template #footer>
      <template v-if="isButton">
        <CDButton
          v-if="!isConfirm"
          :label="rejectLabel"
          :icon="rejectIcon"
          :class="rejectClass"
          @click="reject()"
          class="reject-button -button -waikawaGrey"
        />
        <CDButton
          :label="acceptLabel"
          :icon="acceptIcon"
          :class="acceptClass"
          @click="accept()"
          class="accept-button -button -royalBlue"
          autofocus
        />
      </template>
      <template v-else>
        <slot name="footer" />
      </template>
    </template>
  </CDialog>
</template>

<script>
import ConfirmationEventBus from "primevue/confirmationeventbus";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default {
  name: "ConfirmDialog",
  props: {
    group: String,
    breakpoints: {
      type: Object,
      default: null,
    },
    isConfirm: Boolean, // true : 확인 버튼, false : 아니오 / 예 버튼
    isButton: {
      type: Boolean,
      default: true,
    },
  },
  confirmListener: null,
  closeListener: null,
  data() {
    return {
      visible: false,
      confirmation: null,
    };
  },
  mounted() {
    this.confirmListener = (options) => {
      if (!options) {
        return;
      }

      if (options.group === this.group) {
        this.confirmation = options;
        this.visible = true;
      }
    };

    this.closeListener = () => {
      this.visible = false;
      this.confirmation = null;
    };
    ConfirmationEventBus.on("confirm", this.confirmListener);
    ConfirmationEventBus.on("close", this.closeListener);
  },
  beforeUnmount() {
    ConfirmationEventBus.off("confirm", this.confirmListener);
    ConfirmationEventBus.off("close", this.closeListener);
  },
  methods: {
    accept() {
      if (this.confirmation.accept) {
        this.confirmation.accept();
      }

      this.visible = false;
    },
    reject() {
      if (this.confirmation.reject) {
        this.confirmation.reject();
      }

      this.visible = false;
    },
  },
  computed: {
    header() {
      return this.confirmation ? this.confirmation.header : null;
    },
    message() {
      return this.confirmation ? this.confirmation.message : null;
    },
    blockScroll() {
      return this.confirmation ? this.confirmation.blockScroll : true;
    },
    position() {
      return this.confirmation ? this.confirmation.position : null;
    },
    iconClass() {
      return [
        "p-confirm-dialog-icon",
        this.confirmation ? this.confirmation.icon : null,
      ];
    },
    acceptLabel() {
      return this.confirmation
        ? this.confirmation.acceptLabel || this.$primevue.config.locale.accept
        : null;
    },
    rejectLabel() {
      return this.confirmation
        ? this.confirmation.rejectLabel || this.$primevue.config.locale.reject
        : null;
    },
    acceptIcon() {
      return this.confirmation ? this.confirmation.acceptIcon : null;
    },
    rejectIcon() {
      return this.confirmation ? this.confirmation.rejectIcon : null;
    },
    acceptClass() {
      return [
        "p-confirm-dialog-accept",
        this.confirmation ? this.confirmation.acceptClass : null,
      ];
    },
    rejectClass() {
      return [
        "p-confirm-dialog-reject",
        this.confirmation
          ? this.confirmation.rejectClass || "p-button-text"
          : null,
      ];
    },
  },
  components: {
    CDialog: Dialog,
    CDButton: Button,
  },
};
</script>
<style lang="scss">
@import "@/styles/mixin";
</style>
