import { RouterName } from '@/models/common';
import router from '@/router';
import { useStore } from '@/store';
import { AuthMutationTypes } from '@/store/modules/auth/mutations';
import axios, { AxiosError } from 'axios'

const API_HOST = process.env.NODE_ENV === "production" ? 'https://care.hesel.clinic/heselcare':"https://localhost:44364/PohrTm";
// const API_HOST = 'https://localhost:44364/PohrTm';
// const API_HOST = 'https://192.168.10.91:44364/PohrTm';
// const API_HOST = 'https://heselinc.iptime.org:58444';

export const VIDEO_HOST = process.env.NODE_ENV === "production" ? 'https://care.hesel.clinic:50443':"https://localhost:3000";

const api = axios.create({	baseURL: `${API_HOST}`});
const clearToken = ()=>{
  const store = useStore();
  store.commit(AuthMutationTypes.CLEAR_TOKEN);
}

api.interceptors.response.use(
  (response)=>{
    const path = router.currentRoute.value.path;    
    //ssr로 로그인 화면 소스가 response되면 토큰 초기화 후 로그인 화면으로 이동    
    if(typeof response.data === "string"){
      clearToken();
      if(!path.startsWith("/signin"))
        router.push({name:RouterName.SIGNIN, query:{redirect:path}});
      response.data = "";
    }
    return response},
  (error:AxiosError)=>{

  const fullPath = router.currentRoute.value.fullPath;  
  if(fullPath.startsWith('/online-care') || fullPath.startsWith('/second-opinion') || fullPath.startsWith('/mypage') || fullPath.startsWith('/profile')){

    if(error.response?.status !== 401) return Promise.reject(error);
    
    if(router.currentRoute.value.name !== RouterName.SIGNIN){
      clearToken()
      router.push({name:RouterName.SIGNIN});
    }
  }
  else return;
});

api.defaults.withCredentials = true;
export default api;