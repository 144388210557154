{
  "email": "이메일",
  "password": "비밀번호",
  "passwordConfirm":"비밀번호 확인",
  "signin": "로그인",
  "signup": "회원가입",
  "forgotPassword": "비밀번호 찾기",
  "agree":"동의",
  "confirm":"확인",
  "edit": "수정",
  "agreeAll":"모두 동의",
  "familyName":"성",
  "firstName":"이름",
  "Check your email or password": "로그인 정보를 확인하세요.",
  "User account locked out": "로그인 시도 초과, 잠시 후 다시 시도하세요.",
  "User not found":"사용자를 찾을 수 없습니다.",
  "legal":"개인정보처리방침 / 이용약관",
  "Privacy Policy": "개인정보 처리방침",
  "Agreement":"이용약관",
  "Privacy Policy Content": "개인정보 처리방침",
  "Agreement Content":"이용약관",
  "userName":"유저 이름",
  "phoneNumber":"휴대폰 번호     ",
  "sex":"성별",
  "sexM":"남자",
  "sexF":"여자",
  "birthDate":"생년월일 (2000/01/01)",
  "allowingRecive":"수신동의",
  "sms":"문자",
  "save":"저장",
  "userInfo":"사용자 정보",
  "changePassword":"비밀번호 변경",
  "withdrawal":"회원탈퇴",
  "Required information is required":"필수 정보를 입력하세요.",
  "newPassword":"새로운 비밀번호",
  "newPasswordConfirm":"새로운 비밀번호 확인",
  "Password not correct": "비밀번호가 일치하지 않습니다.",
  "doctor or dept":"의사 or 진료과",
  "doctor name":"의사명",
  "search":"검색",
  "profile":"개인정보",
  "request":"의뢰",
  "response":"회신",
  "proceeding":"진행 중",
  "complete":"완료",
  "OnlineCare":"온라인 대면진료 (처방전)",
  "SecondOpinion":"Second Opinion (2차소견)",
  "SecondOpinion2":"2차소견",
  "newRequest":"신청하기",
  "Requested":"예약신청",
  "Scheduled":"예약확정",
  "Completed":"회신완료",
  "Canceled":"취소",
  "addFiles":"첨부파일",
  "FileManager":"파일 관리",
  "List of Doctors":"의사 선택",
  "Next":"다음",
  "Prev":"이전",
  "Cancel":"취소",
  "Return":"되돌아가기",
  "SaveHeader":"저장 하시겠습니까?",  
  "Video":"화상상담",
  "validateError":"필수 입력 항목을 입력하세요.",
  "Status":"현황",
  "InfoModify":"회원정보수정",
  "Signout":"로그아웃",
  "Attachments": "첨부",
  "default": "기본",
  "reset":"선택취소",
  "title":"제목",
  "FileTitle":"파일이름",
  "DataType":"파일종류",
  "FileType":"파일확장자",
  "Remove":"삭제",
  "prescription":"처방전",
  "pharmacy":"약국",
  "insertDateTime":"신청일자", 
  "doctor":"의사",
  "scheduledDatetime":"예약일시",
  "totalCallTime":"통화시간",
  "respondDateTime":"회신일자",
  "CheckingItNow":"확인 중 입니다.",
  "back":"뒤로가기",
  "Saved":"저장 완료.",
  "CalcelHeader": "취소하시겠습니까?",
  "Yes":"예",
  "No":"아니오",
  "WithdrawalHeader":"회원을 탈퇴합니다.",
  "Result":"결과",
  "FileAttachments": "증상사진첨부",
  "MyPage":"마이페이지",
  "registrationcompleted":"가입완료",
  "confirmemail":"메일확인",
  "resetpassword":"패스워드 변경",
  "Page not found":"페이지를 찾을 수 없습니다.",
  "Unable to load user":"사용자를 찾을 수 없습니다.",
  "EmailSendOK":"입력하신 이메일로 메일이 발송되었습니다.",
  "Dispense":"조제완료",
  "ForOpinion":"소견신청",
  "accept":"동의하기",
  "reject": "철회하기",
  "FileChoose": "파일 선택",
  "FileCancel": "첨부 취소",
  "Fileupload": "파일 첨부",
  "Drag and drop files to here to upload.":"첨부 파일을 마우스로 끌어 넣으시거나 [파일선택] 버튼을 클릭하세요.",
  "save response" : "회신 저장",
  "LastOrder":"최근 결제",
  "more":"더 보기",
  "Error" : "에러",
  "resetPasswordCompleted":"패스워드 변경 성공",
  "Refused":"반려",
  "Your email is not verified": "가입한 이메일로 HESEL이 메일을 보냈습니다. \n 이메일을 열어 [계정활성화] 버튼을 눌러 주세요. \n 그 다음, 로그인이 가능합니다.",
  "newRequestOnlineCare":"예약신청",
  "newRequestSecondOpinion":"2차 소견 신청하기",
  "confirm2":"내용 확인",
  "from date": "시작일",
  "to date": "종료일",
  "EmailCheck": "중복확인",
  "PharmacyNone": "선택 안함",
  "SavedFavorites": "주치의로 선택하였습니다.",
  "Select a Dept": "진료과 선택",
  "ReceptionCompleted": "접수 완료",
  "ReceptionSaveHeader": "접수 하시겠습니까?",
  "emptyMessage":"",
  "dayNames": "일요일,월요일,화요일,수요일,목요일,금요일,토요일",
  "dayNamesShort": "일,월,화,수,목,금,토",
  "dayNamesMin": "일,월,화,수,목,금,토",
  "monthNames": "1월,2월,3월,4월,5월,6월,7월,8월,9월,10월,11월,12월",
  "monthNamesShort": "1월,2월,3월,4월,5월,6월,7월,8월,9월,10월,11월,12월",
  "RequestedQuick":"빠른 진료 신청"
}