import { RoleNames } from "@/models/account"

export const state:State = {
  token: '',
  role:'' as RoleNames | "",
  name:'',
  uid:'',
  userName: '',
  facilityKey: 0,
  pid:'',
  isRRN:0
}

export type State={
  token: string,
  role: RoleNames | "",
  name: string,
  uid: string,
  userName: string,
  facilityKey: number,
  pid:string,
  isRRN:number
}
