<i18n>
{
  "en": {
    "no-display" : "no"
  },
  "ja": {
    "no-display" : "1no"

  },
  "ko":{
    "no-display" : "화면이 너무 작습니다."
  }
}
</i18n>
<template>
  <div
    class="app -stop-dragging"
    :class="!!getGlobalLoading ? 'dialog--open' : ''"
  >
    <!-- <template v-if="isLargerMin"> -->
    <AppLayout v-if="!isLoading" />
    <div v-else></div>
    <!-- </template> -->
    <!-- <div class="minimum-size" v-else>{{ l("no-display") }}</div> -->
    <GlobalProgressSpinner v-if="getGlobalLoading" />
    <Scroll />
  </div>
</template>
  
  <script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import AppLayout from "./layout/AppLayout.vue";
// import messaging from "./services/firebase";
import { useStore } from "@/store";
import { AuthMutationTypes } from "./store/modules/auth/mutations";
import GlobalProgressSpinner from "@/components/common/GlobalProgressSpinner.vue";
import { mapGetters } from "vuex";
import { AuthActionTypes } from "./store/modules/auth/actions";
import Scroll from "@/components/common/Scroll.vue";

export default defineComponent({
  setup() {
    const { t: l } = useI18n();
    const store = useStore();
    const isLoading = ref(true);

    // // firebase
    // let token = ref("");
    // try {
    //   if (typeof messaging !== "string") {
    //     messaging.getToken().then((currentToken: string) => {
    //       token.value = currentToken;
    //     });

    //     messaging.onMessage((payload) => {
    //       const {
    //         data: { title, message },
    //       } = payload;
    //       console.log(title, message);
    //       alert(message);
    //     });
    //   }
    // } catch (e) {
    //   console.error(e);
    // }

    const width = ref(window.innerWidth);

    const handleResize = () => {
      width.value = window.innerWidth;
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      const token = localStorage.getItem("access");
      // console.log("Token", token);
      if (token) {
        store.commit(AuthMutationTypes.SET_TOKEN, token);
        try {
          await store.dispatch(AuthActionTypes.GET_ACCOUNT_INFO);

          const role = store.getters.getAccountInfo?.RoleName;
          if (
            !!role &&
            role !== "Users" &&
            process.env.NODE_ENV === "production"
          ) {
            window.location.href = `/${process.env.VUE_APP_SUB_URL}`;
          }
        } catch (e) {
          console.error(e);
        }
      }
      isLoading.value = false;
    });
    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });
    const isLargerMin = computed(() => width.value > 347);

    return {
      // token,
      isLargerMin,
      isLoading,
      l,
    };
  },
  components: {
    AppLayout,
    GlobalProgressSpinner,
    Scroll,
  },
  computed: {
    ...mapGetters(["getGlobalLoading"]),
  },
});
</script>
  
<style lang="scss" scoped>
@import "@/styles/mixin";
.app {
  height: 100%;
  width: 100%;
  position: relative;
  // overflow: hidden scroll;
  // overflow-x: hidden;
  &.dialog--open {
    height: 100vh;
    overflow: hidden;
  }
  .minimum-size {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $Grey3;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>
