{
  "email": "이메일e",
  "password": "비밀번호e",
  "passwordConfirm":"비밀번호 확인e",
  "signin": "로그인e",
  "signup": "회원가입e",
  "forgotPassword": "비밀번호 찾기e",
  "agree":"동의e",
  "confirm":"확인e",
  "agreeAll":"모두 동의e",
  "familyName":"성e",
  "firstName":"명e",
  "Check your email or password": "로그인 정보를 확인하세요.e",
  "User account locked out": "로그인 시도 초과, 잠시 후 다시 시도하세요.e",
  "User not found":"사용자를 찾을 수 없습니다.e",
  "legal":"법적고지e",
  "Privacy Policy": "개인정보 처리방침e",
  "Agreement":"법적 고지e",
  "userName":"유저 이름e",
  "phoneNumber":"전화번호e",
  "sex":"성별e",
  "sexM":"남자e",
  "sexF":"여자e",
  "birthDate":"생년월일e",
  "allowingRecive":"수신동의e",
  "sms":"문자e",
  "save":"저장e",
  "userInfo":"사용자 정보e",
  "changePassword":"비밀번호 변경e",
  "withdrawal":"회원탈퇴e",
  "Required information is required":"필수 정보를 입력하세요.e",
  "newPassword":"새로운 비밀번호e",
  "newPasswordConfirm":"새로운 비밀번호 확인e",
  "Password not correct": "비밀번호가 일치하지 않습니다.e",
  "doctor or dept":"의사 or 진료과e",
  "search":"검색e",
  "from date": "From",
  "to date": "To"
}